var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.data.style.type == "singleton"
      ? _c(
          "div",
          { style: _vm.style },
          _vm._l(_vm.imgList, function (item, index) {
            return _c(
              "div",
              { key: index },
              [
                _c(
                  "el-image",
                  {
                    style: {
                      marginTop:
                        index > 0 ? _vm.data.style.imgbackGuge + "px" : "",
                      borderRadius: _vm.data.style.borderRadius
                        ? _vm.data.style.borderRadius + "px"
                        : "0px",
                    },
                    attrs: { src: item.image },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "x-c",
                        staticStyle: { height: "200px" },
                        attrs: { slot: "error" },
                        slot: "error",
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-picture-outline",
                          staticStyle: {
                            "font-size": "40px",
                            color: "#ababab",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ],
              1
            )
          }),
          0
        )
      : _vm.data.style.type == "swiper"
      ? _c(
          "div",
          { style: _vm.style },
          [
            _c(
              "el-carousel",
              {
                attrs: {
                  height: _vm.carouselHeight,
                  interval: _vm.data.style.interval,
                },
              },
              _vm._l(_vm.imgList, function (item, index) {
                return _c(
                  "el-carousel-item",
                  { key: index },
                  [
                    _c("el-image", {
                      style: {
                        borderRadius: _vm.data.style.borderRadius
                          ? _vm.data.style.borderRadius + "px"
                          : "0px",
                        height: _vm.carouselHeight,
                      },
                      attrs: { src: item.image },
                      on: {
                        load: function (event) {
                          return _vm.updateCarouselHeight(event, index)
                        },
                      },
                    }),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        )
      : _vm.data.style.type == "roll"
      ? _c("div", { staticClass: "rollBox", style: _vm.style }, [
          _c(
            "div",
            {
              style: {
                width:
                  "calc(100% - " + _vm.data.style.pageBackGauge * 2 + "px)",
              },
            },
            [
              _c(
                "el-scrollbar",
                _vm._l(_vm.imgList, function (item, index) {
                  return _c(
                    "el-image",
                    {
                      key: index,
                      staticStyle: { width: "100%", "margin-right": "10px" },
                      style: [
                        _vm.imgStyle,
                        {
                          marginLeft:
                            index > 0 ? _vm.style.imgbackGuge + "px" : "",
                        },
                        {
                          borderRadius: _vm.data.style.borderRadius
                            ? _vm.data.style.borderRadius + "px"
                            : "0px",
                        },
                        { height: _vm.imageHeight },
                      ],
                      attrs: { id: "myImage" + index, src: item.image },
                      on: {
                        load: function ($event) {
                          return _vm.handleImageLoad($event, index)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "x-c",
                          attrs: { slot: "error" },
                          slot: "error",
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-picture-outline",
                            staticStyle: {
                              "font-size": "40px",
                              color: "#ababab",
                            },
                          }),
                        ]
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }